<template>
  <van-form @submit="onSubmit">
    <template v-for="item in items" :key="item">
      <van-cell-group title=" " inset>
        <van-field
          v-model="item.user.userId"
          label="账户id"
          placeholder="请输入"
          disabled
        />
        <van-field
          v-model="item.user.phone"
          label="手机号"
          placeholder="请输入"
          disabled
        />
        <van-field
          v-model="item.name"
          label="称呼"
          placeholder="请输入"
          disabled
        />
        <van-field
          v-model="item.commission"
          type="number"
          label="分润金额"
          placeholder="请输入"
        />
      </van-cell-group>
    </template>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        保存配置
      </van-button>
    </div>
    <div style="margin: 16px;">
      <van-button round block @click="onAdd">
        选择员工
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  emits: ['select'],
  setup (props, { emit }) {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      productId: useRoute.query.productId,
      items: [
        // {
        //   userId: 9527,
        //   name: '闷闷',
        //   phone: '13890289305',
        //   commission: 0
        // }
      ]
    })
    /*
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    */
    const onAdd = () => {
    }
    const onSubmit = (values) => {
      console.log(state.items)
    }
    const onClick = (row) => {
      emit('select', row)
    }
    const init = () => {
      post('/dividend.list', {
        productId: state.productId
      }).then(res => {
        state.items = res.data.content
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onClick,
      onAdd
    }
  }
}
</script>

<style scoped>
body {
  background:#ff6600;
}
</style>
